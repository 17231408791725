import { client } from '../client';
import {
  IQuestion,
  IQuestionDocument,
  IQuestionListParams,
  IQuestionListResponse,
} from '../question';

const get = (id: string) => {
  return client.get<IQuestionDocument>(`/users/me/questions/${id}`);
};

const create = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/users/me/questions`, question);
};

const update = (question: IQuestionDocument) => {
  return client.put<IQuestionDocument>(
    `/users/me/questions/${question._id}`,
    question
  );
};

const remove = (id: string) => {
  return client.delete(`/users/me/questions/${id}`);
};

const list = (params: IQuestionListParams) => {
  return client.get<IQuestionListResponse>(`/users/me/questions/`, { params });
};

export const question = { get, create, update, remove, list };

import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedUserDocument,
} from './types';

export interface IMaterialListParams extends IListParams {
  course?: ObjectId;
  batch?: ObjectId;
  topic?: ObjectId;
  populate?: boolean;
  ids?: string;
}

export interface IMaterial {
  materialId?: ObjectId;
  topicId: ObjectId | any[];
  isPrivate: String;
  sessionMaterial: ObjectId[] | any[];
  quiz: ObjectId[] | any[];
  notes: ObjectId[] | any[];
  library: ObjectId[] | any[];
}

export interface IMaterialDocument extends IMaterial, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IMaterialListResponse {
  totalItems: number;
  batcheMaterials: IMaterialDocument[];
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IMaterialDocument>(`/batches/material/${id}`, { params });
};

const create = (material: IMaterial) => {
  return client.post<IMaterialDocument>(`/batches/material`, material);
};

const update = (material: IMaterialDocument) => {
  return client.put<IMaterialDocument>(
    `/batches/material/${material._id}`,
    material
  );
};

const remove = (id: string) => {
  return client.delete(`/batches/material/${id}`);
};

const list = (params: IMaterialListParams) => {
  return client.get<IMaterialListResponse>(`/batches/material/`, { params });
};

export const batchMaterial = { get, create, update, remove, list };

import { UserRole } from './user/user';
import { client } from './client';
import { IDocument, IListParams, PopulatedDocument, ObjectId } from './types';

export interface IEducatorEducation {
  educationType: string;
  institutionName: string;
  courseName: string;
  percentage: string;
  passYear: string;
  certificates: string[];
}

export interface IEducatorExperience {
  institutionName: string;
  designation: string;
  start: string;
  end: string;
  isCurrent: Boolean;
}

export interface ISubjectDetail {
  course: string | PopulatedDocument;
  subjects: string[] | PopulatedDocument[];
}

export interface IEducatorDocument {
  documentType: string;
  asset: string;
}

export interface IEducator {
  name: string;
  dateOfBirth: string;
  gender: string;
  languages: string;
  mobile: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  displayPicture: string;
  education: IEducatorEducation[];
  experience: IEducatorExperience[];
  subjectDetail: ISubjectDetail;
  documents: IEducatorDocument[];
  active: boolean;
  isFeatured: boolean;
  appreciations: number;
  profileViews: number;
}

export interface IEducatorSearch {
  subject_ids?: ObjectId[];
  course_id?: ObjectId;
  name?: string;
}

export interface IEducatorDocument extends IEducator, IDocument {}

export interface IEducatorListResponse {
  totalItems: number;
  educators: IEducatorDocument[];
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IEducatorDocument>(`/users/${id}`, { params });
};

const create = (educator: IEducator) => {
  return client.post<IEducatorDocument>(`/users`, {
    ...educator,
    role: UserRole.EDUCATOR,
  });
};

const update = (educator: IEducatorDocument) => {
  return client.put<IEducatorDocument>(`/users/${educator._id}`, educator);
};

const approve = (id: string) => {
  return client.put<IEducatorDocument>(`/users/${id}`, {
    status: 'APPROVED',
  });
};

const unapprove = (id: string) => {
  return client.put<IEducatorDocument>(`/users/${id}`, {
    status: 'UNAPPROVED',
  });
};

const remove = (id: string) => {
  return client.delete(`/educators/${id}`);
};

const list = (params: IListParams) => {
  return client.get<IEducatorListResponse>(`/users/`, {
    params: { ...params, role: UserRole.EDUCATOR },
  });
};

// const search = (params: IEducatorSearch) => {
//   return client.get<IEducatorListResponse>('educators/search', { params });
// };

export const educator = {
  // search,
  get,
  create,
  update,
  remove,
  list,
  approve,
  unapprove,
};

import React, { useState } from 'react';
import { InputProps } from './input.types';
import { useField, useFormikContext } from 'formik';
import { Label } from '../label/label';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { ErrorMessage } from '../error/error';

export const Input = ({
  id,
  label,
  required,
  name,
  placeholder,
  type = 'text',
  takeSpace,
  className,
  style,
  prefix,
  suffix,
  disabled,
  maxLength,
  suffixClick,
  wordCount,
  onChange
}: // onChange,
  InputProps) => {
  const [field, meta] = useField({ name });
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;
  const [stateSuffix, setStateSuffix] = useState(
    type == 'password' ? <FaRegEye /> : suffix
  );
  const hasPrefix = !!prefix;
  const hasSuffix = !!stateSuffix;

  const onSuffixClick = () => {
    if (type == 'password') {
      setIsVisible(!isVisible);
      setStateSuffix(!isVisible ? <FaRegEyeSlash /> : <FaRegEye />);
    }

    suffixClick && suffixClick();
  };

  const onInputChange = (event: any) => {
    console.log(field.value, 'field');
    if (onChange != undefined) {
      onChange(event.target.value);
    }
    setCount(event.target.value.length);
  }

  const input = (
    <input
      type={type == 'password' && isVisible ? 'text' : type}
      id={id || name}
      disabled={disabled}
      className={`primary-form-control ${hasPrefix ? 'form-control-prepended' : ''
        } ${hasSuffix ? 'form-control-appended' : ''} ${showError ? 'is-invalid' : ''
        }`}
      placeholder={placeholder}
      maxLength={maxLength}
      {...field}
      onChange={(e) => {
        field.onChange(e);
        onChange ? onInputChange(e) : console.log(e.target.value, "a")
      }}

    />
  );

  return (
    <div className={className || 'form-group'} style={style}>
      <Label label={label} id={id} takeSpace={takeSpace} required={required} />
      {hasPrefix || hasSuffix ? (
        <div
          className={`input-group input-group-merge rounded ${showError ? 'is-invalid' : ''
            }`}
        >
          {hasPrefix && (
            <div className="input-group-prepend">
              <div className="input-group-text">{prefix}</div>
            </div>
          )}
          {input}
          {wordCount &&
            <div>{`${count}/${maxLength || "25"}`}</div>
          }
          {hasSuffix && (
            <div className="input-group-append">
              <div
                style={{ cursor: 'pointer' }}
                className="input-group-text"
                onClick={() => onSuffixClick()}
              >
                {stateSuffix}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {input}
          {wordCount &&
            <div>{`${count}/${maxLength || "25"}`}</div>
          }
        </div>

      )}
      <ErrorMessage meta={meta} />
    </div>
  );
};

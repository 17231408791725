import React from 'react';
import { ITableProps } from '../table/table.types';

const Table = ({
  columns,
  data,
  isDraggable,
  handleOnDragOver,
  handleOnDragEnd,
}: ITableProps) => {
  return (
    <>
      <div className="primary-paginated-table">
        {columns && columns.length && columns[0].title && (
          <div className="primary-paginated-table--head">
            <div className="primary-paginated-table--row">
              {columns.map((column, index) => (
                <div
                  className={`primary-paginated-table--col ${column.alignment ||
                    ''}`}
                  style={{ width: column.width }}
                  key={`${column.title}-${index}`}
                >
                  {column.isSortable ? (
                    <a className="sort">{column.title}</a>
                  ) : (
                    column.title
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="primary-paginated-table--body">
          {data &&
            data.map((dataItem, index) => (
              <div
                className="primary-paginated-table--row"
                key={`row-${index}`}
                draggable={isDraggable}
                onDragOver={() => handleOnDragOver(dataItem, index)}
                onDragEnd={() => handleOnDragEnd(dataItem, index)}
              >
                {columns.map(({ dataRenderer, alignment, width }, idx) => (
                  <div
                    className={`primary-paginated-table--col ${alignment ||
                      ''}`}
                    style={{ width: width }}
                    key={`td-${idx}`}
                  >
                    {dataRenderer(dataItem, index)}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>

      {/* <div className="table-responsive-md">
      <table className="table mb-0 thead-border-top-0 table-nowrap">
        {columns && columns.length && columns[0].title && (
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  style={{ width: column.width }}
                  className={column.alignment}
                  key={`${column.title}-${index}`}
                >
                  {column.isSortable ? (
                    <a className="sort">{column.title}</a>
                  ) : (
                    column.title
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="list">
          {data &&
            data.map((dataItem, index) => (
              <tr key={`row-${index}`}>
                {columns.map(({ dataRenderer, alignment }, idx) => (
                  <td key={`td-${idx}`} className={alignment}>
                    {dataRenderer(dataItem, index)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div> */}
    </>
  );
};

export { Table };

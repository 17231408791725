import { client } from '../client';
import {
  ICourseware,
  ICoursewareDocument,
  ICoursewareListParams,
  ICoursewareListResponse,
} from '../courseware';

const get = (id: string) => {
  return client.get<ICoursewareDocument>(`/users/me/courseware/${id}`);
};

const create = (courseware: ICourseware) => {
  return client.post<ICoursewareDocument>(`/users/me/courseware`, courseware);
};

const update = (courseware: ICoursewareDocument) => {
  return client.put<ICoursewareDocument>(
    `/users/me/courseware/${courseware._id}`,
    courseware
  );
};

const remove = (id: string) => {
  return client.delete(`/users/me/courseware/${id}`);
};

const list = (params: ICoursewareListParams) => {
  return client.get<ICoursewareListResponse>(`/users/me/courseware/`, {
    params,
  });
};

export const courseware = { get, create, update, remove, list };

import { client } from './client';

import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

import { IUserTestAttempt } from './user-test-attempt';
export interface IBatchListParams extends IListParams {
  courseId?: ObjectId;
  status?: batchStatus;
  user?: ObjectId;
  educator?: ObjectId;
  student?: ObjectId;
  batchType?: string;
}
export interface IGenericDocument {
  refId: ObjectId | PopulatedDocument | null;
  name: string | null;
  isDeleted: Boolean | null;
}

export interface IDurationDocument {
  numOfDays: Number | null;
  numOfHours: Number | null;
  numOfClasses: Number | null;
}

export interface IFileDocuemnt extends IGenericDocument {
  orderId: Number | null;
}

export interface ISyllabusDocument {
  refId: ObjectId | PopulatedDocument | null;
  sessionId: ObjectId | PopulatedDocument | null;
  isDeleted: Boolean | null;
}

export interface IBatchStudent extends IGenericDocument {
  joinedOn: Date | null;
  blockReason: String | null;
}

export interface ITeacherList extends IGenericDocument {
  name: string;
  id: string;
}

export interface ISubjectList {
  subjectId: ObjectId | PopulatedDocument | null;
  teacherId: ObjectId | PopulatedDocument | null;
}
export interface IStudentBatchSubjectList extends Document {
  subjectId: ObjectId | PopulatedDocument | null;
}
export interface IStudentBatchSubjectTeacherList extends Document {
  teacherId: ObjectId | PopulatedDocument | null;
}
export interface IBatch {
  name: string | null;
  batchType: string | null;
  displayPicture: string | null;
  nameIdx: string | null;
  course: IGenericDocument;
  description: string | null;
  shortDescription: string | null;
  exams: string[] | null;
  status: batchStatus | null;
  duration: IDurationDocument[];
  startDate: Date | null;
  endDate: Date | null;
  batchIntro: string | null;
  syllabus: ISyllabusDocument[];
  details: string | null;
  isActive: Boolean | null;
  tests: IGenericDocument[];
  freeResources: IGenericDocument[];
  batchPaymentType: String | null;
  price: Number | null;
  students: IBatchStudent[];
  chatId: String | null;
  videoRoomUrl: String | null;
  totalVideos?: Number | null;
  teacherList?: ITeacherList[];
  studentCount?: Number | null;
  subjectList?: ISubjectList[];
  testAttempts?: IUserTestAttempt[];
  sessions?: IBatchSession[];
  lessonAttended?: Number;
  sessonAttended?: Number;
}

export interface IBatchSubject {
  course: IGenericDocument;
  // exam: IGenericDocument;
  subject: IGenericDocument;
}

export interface IBatchTopic extends IDocument {
  subjectId: IGenericDocument;
  topicId: IGenericDocument;
}

export interface ISubjectTeacher extends IDocument {
  teacherId: IGenericDocument;
  displayOrder?: Number;
}

export interface ITopicNotes extends IBatchTopic {
  notesId: String;
  name: String;
  isFree?: Boolean;
}

export interface ITopicQuiz extends IBatchTopic {
  quizId: String;
  isFree?: Boolean;
}

export interface IBatchTest extends IDocument {
  testId: IGenericDocument;
  isFree: Boolean;
}

export interface IBatchResource extends IDocument {
  resrcId: IGenericDocument;
  user?: ObjectId;
}

export interface IBatchDocument extends IBatch, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
  actualPrice: Number | null;
}
export interface IBatchSubscriptionDocument extends IDocument {
  batchId: IBatch;
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IBatchListResponse {
  totalItems: number;
  batches: IBatchDocument[];
}

export enum batchStatus {
  'INIT',
  'DRAFT',
  'RECONCILE',
  'INACTIVE',
  'PUBLISH',
}

export enum status {
  'INIT',
  'PUBLISH',
}
export interface ITime {
  hh: string;
  mm: string;
}

export enum materialType {
  'MATERIAL',
  'NOTES',
}
export interface ISessionMaterial {
  coursewareId: ObjectId | PopulatedUserDocument;
  orderId: number;
  type: materialType;
}

export interface ISessionParms {
  subject?: ObjectId;
  topics?: ObjectId[];
  sessionDate?: Date;
  sessionStartTime?: ITime;
  sessionEndTime?: ITime;
  batchStartDate?: Date;
  batchEndDate?: Date;
  sameDay?: Boolean;
}

export interface IBatchSession extends IDocument {
  batchId?: ObjectId | PopulatedDocument;
  sessionDate?: Date;
  sessionStartTime?: ITime;
  sessionEndTime?: ITime;
  sessionInterval?: Number;
  subject?: ObjectId | PopulatedUserDocument;
  topics?: ObjectId[] | PopulatedUserDocument[];
  sessionName?: string;
  description?: string;
  sessionFiles?: ISessionMaterial[];
  status?: status;
}

export enum progressType {
  'VIDEO',
  'SESSION',
}

export interface IProgressData extends IDocument {
  batchId: ObjectId;
  progessType: progressType;
  progressDetail: string;
}

export interface IUpdateTopicNotes {
  batchTopicId: ObjectId;
  noteId: string;
  isFree: boolean;
}

export interface IUpdateTopicQuiz {
  batchTopicId: ObjectId;
  quizId: string;
  isFree: boolean;
}

const create = (options: { batchType: string }) => {
  return client.post<IBatchDocument>(`/batches`, options);
};

const updateBasicDetails = (batchId: string, batch: IBatchDocument) => {
  const url = `/batches/${batchId}/updateBasicDetail`;
  const param = {
    courseId: batch.course.refId,
    price: batch.price,
    shortDescription: batch.shortDescription,
    description: batch.description,
    batchIntro: batch.batchIntro,
    displayPicture: batch.displayPicture,
    exams: batch.exams,
    name: batch.name,
    actualPrice: batch?.actualPrice,
    startDate: batch?.startDate,
    endDate: batch?.endDate,
    isActive: batch?.isActive,
  };
  return client.put<IBatchDocument>(url, param);
};

const updateSubjects = (batchId: string, params: IBatchSubject) => {
  const url = `/batches/${batchId}/addSubject`;
  return client.put<IBatchDocument>(url, params);
};

const removeSubject = (batchId: string, subjectId: string) => {
  const url = `/batches/${batchId}/removeSubject`;
  const params = {
    batchId: batchId,
    subjectId: subjectId,
  };
  return client.put<IBatchDocument>(url, params);
};

const addTopics = (batchId: string, batchTopic: IBatchTopic) => {
  const url = `/batches/${batchId}/addTopic`;
  return client.put<IBatchDocument>(url, batchTopic);
};

const removeTopics = (batchId: string, batchTopic: IBatchTopic) => {
  const url = `/batches/${batchId}/removeTopic`;
  const params = {
    ...batchTopic,
    batchId: batchId,
  };
  return client.put<IBatchDocument>(url, params);
};

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IBatchDocument>(`/batches/${id}`, { params });
};

const assignTeacher = (batchId: string, batchTopic: ISubjectTeacher) => {
  const url = `/batches/${batchId}/assignTeacher`;
  return client.put<IBatchDocument>(url, batchTopic);
};

const removeTeacher = (batchId: string, batchTopic: ISubjectTeacher) => {
  const url = `/batches/${batchId}/removeTeacher`;
  const params = {
    teacherId: batchTopic.teacherId,
  };
  return client.put<IBatchDocument>(url, params);
};

const addNotes = (batchId: string, notes: ITopicNotes) => {
  const url = `/batches/${batchId}/addNote`;
  return client.put<IBatchDocument>(url, notes);
};

const updateNote = (batchId: string, notes: IUpdateTopicNotes) => {
  const url = `/batches/${batchId}/updateNote`;
  return client.put<IBatchDocument>(url, notes);
};

const updateQuiz = (batchId: string, notes: IUpdateTopicQuiz) => {
  const url = `/batches/${batchId}/updateQuiz`;
  return client.put<IBatchDocument>(url, notes);
};

const removeNote = (batchId: string, notes: ITopicNotes) => {
  const url = `/batches/${batchId}/removeNote`;
  return client.put<IBatchDocument>(url, notes);
};

const addQuiz = (batchId: string, notes: ITopicQuiz) => {
  const url = `/batches/${batchId}/addQuiz`;
  return client.put<IBatchDocument>(url, notes);
};

const purchaseBatch = (batch: IBatchResource) => {
  const url = `/batches/${batch._id}/buynow`;
  return client.post<IBatchResource>(url, batch);
};

const listPurchasedBatches = (params?: IBatchListParams) => {
  return client.get<IBatchSubscriptionDocument>(`/batches/me`, { params });
};

const removeQuiz = (batchId: string, notes: ITopicQuiz) => {
  const url = `/batches/${batchId}/removeQuiz`;
  return client.put<IBatchDocument>(url, notes);
};

const addTest = (batchId: string, batchTest: IBatchTest) => {
  const url = `/batches/${batchId}/addTest`;
  return client.put<IBatchDocument>(url, batchTest);
};

const updateTest = (batchId: string, batchTest: IBatchTest) => {
  const url = `/batches/${batchId}/updateTest`;
  return client.put<IBatchDocument>(url, batchTest);
};

const removeTest = (batchId: string, batchTest: IBatchTest) => {
  const url = `/batches/${batchId}/removeTest`;
  return client.put<IBatchDocument>(url, batchTest);
};

const buyNow = (batchId: string) => {
  const url = `/batches/${batchId}/buyNow`;
  return client.post<IBatchStudent>(url, batch);
};

const list = (params: IBatchListParams) => {
  const url = `/batches`;
  return client.get<IBatchDocument[]>(url, { params });
};

const addFreeSrc = (batchId: string, batchResource: IBatchResource) => {
  const url = `/batches/${batchId}/addFreeSrc`;
  return client.put<IBatchDocument>(url, batchResource);
};

const removeFreeSrc = (batchId: string, batchResource: IBatchResource) => {
  const url = `/batches/${batchId}/removeFreeSrc`;
  return client.put<IBatchDocument>(url, batchResource);
};

const publish = (batchId: string, publish: boolean = true) => {
  const url = `/batches/${batchId}/publish`;
  return client.put<IBatchDocument>(url, { publish });
};

const teacherPublish = (batchId: string) => {
  const url = `/batches/${batchId}/teacher/publish`;
  return client.put<IBatchDocument>(url);
};

const getTeacherBatchList = (
  teacherId: string,
  params?: {
    populate: boolean;
    courseId?: string;
  }
) => {
  const url = `/batches/educator/${teacherId}`;
  return client.get<IBatchDocument>(url, { params });
};

const getMyBatchSubjects = (params: IBatchListParams) => {
  const url = `/batches/student/subject`;
  return client.get<IStudentBatchSubjectList>(url, { params });
};

const getMyBatchSubjectTeachers = (
  subjectId: string,
  params: IBatchListParams
) => {
  const url = `/batches/student/subject/${subjectId}/teacher`;
  return client.get<IStudentBatchSubjectTeacherList>(url, { params });
};

const addSession = (batchId: string, session: ISessionParms) => {
  return client.post<IBatchDocument>(`/batches/${batchId}/addSlot`, session);
};

const updateSession = (batchId: string, session: IBatchSession) => {
  return client.put<IBatchSession[]>(
    `/batches/${batchId}/updateSession`,
    session
  );
};

const removeSession = (batchId: string, session: IBatchSession) => {
  return client.delete<IBatchSession[]>(
    `/batches/${batchId}/removeSession/${session._id}`
  );
};

const getBatchStudents = (batchId: string, params: IListParams) => {
  const url = `/batches/${batchId}/students`;
  return client.get<IStudentBatchSubjectList>(url, { params });
};

const addBatchProgress = (id: string, data: IProgressData) => {
  return client.post<any>(`/batches/${id}/progress`, data);
};

const statusUpdate = (id: string, data: { status: batchStatus }) => {
  return client.post<any>(`/batches/${id}/statusUpdate`, data);
};

const deleteBatch = (id: string) => {
  return client.delete<any>(`/batches/${id}`);
};

const getPDFDownload = (id: string) => {
  return client.get<any>(`/batches/${id}/pdf`, { responseType: 'blob' });
};

export const batch = {
  create,
  updateBasicDetails,
  updateSubjects,
  removeSubject,
  get,
  addTopics,
  removeTopics,
  assignTeacher,
  removeTeacher,
  addNotes,
  removeNote,
  addQuiz,
  removeQuiz,
  addTest,
  removeTest,
  updateTest,
  addFreeSrc,
  purchaseBatch,
  listPurchasedBatches,
  buyNow,
  list,
  removeFreeSrc,
  publish,
  teacherPublish,
  getTeacherBatchList,
  getMyBatchSubjects,
  getMyBatchSubjectTeachers,
  addSession,
  updateSession,
  removeSession,
  addBatchProgress,
  getBatchStudents,
  updateNote,
  updateQuiz,
  statusUpdate,
  deleteBatch,
  getPDFDownload,
};

import React from 'react';

const PageSeparator = ({ title }: { title: string }) => {
  return (
    <div className="page-separator">
      <div className="page-separator__text">{title}</div>
    </div>
  );
};

export { PageSeparator };

import React, { useEffect, useRef, useCallback } from 'react';
import { useField } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { Label } from '../label/label';
import { ErrorMessage } from '../error/error';
import { RichTextEditorProps } from './rich-text-editor.types';
import {
  UploadingOverlay,
  LoadingRichText,
  RichTextContainer,
} from './rich-text-editor.styled';

export const RichTextEditor = ({
  id,
  name,
  label,
  maxHeight,
  required,
  uploadFile,
  getAssetUrl,
}: RichTextEditorProps) => {
  let timerId: any = null;
  let timerId2: any = null;
  const tinyMce = useRef<any>();
  const [field, meta, helpers] = useField({ name });
  const [isEditorLoaded, setIsEditorLoaded] = React.useState(false);
  const [disabledEditor, setDisabledEditor] = React.useState(true);
  const [isImageUploading, setIsImageUploading] = React.useState(false);

  useEffect(() => {
    return reset;
  }, []);

  const onInit = useCallback(
    editor => {
      tinyMce.current = editor;
    },
    [field.value]
  );

  useEffect(() => {
    setInitialValueEditor('useEffect');
  }, [field.value, tinyMce.current]);

  const reset = () => {
    clearTimeout(timerId);
    clearTimeout(timerId2);
  };

  const config = {
    oninit: onInit,
    setup: onInit,
    menubar: false,
    width: '100%',
    min_height: 200,
    max_height: maxHeight || 'inherit',
    autoresize_bottom_margin: 0,
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      imagesUploadHandler(blobInfo, success, failure);
    },
    plugins:
      'advlist autolink lists link image charmap anchor searchreplace visualblocks code media table hr powerpaste autoresize',
    toolbar:
      'insertfile undo redo | styleselect | bullist numlist | outdent indent | charmap | tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry | code | hr | link | image | table | forecolor | backcolor',
    external_plugins: {
      tiny_mce_wiris:
        'https://cdn.jsdelivr.net/npm/@wiris/mathtype-tinymce4@7.24.6/plugin.min.js',
    },
    powerpaste_allow_local_images: true,
    powerpaste_word_import: 'prompt',
    powerpaste_html_import: 'prompt',
  };

  const imagesUploadHandler = (blobInfo: any, success: any, failure: any) => {
    setIsImageUploading(true);
    uploadFile({ file: blobInfo.blob() })
      .then((res: any) => {
        success(getAssetUrl(res?.data?.key));
        setIsImageUploading(false);
      })
      .catch((error: any) => {
        console.log('error', error?.data?.message);
        failure(`Please upload separate single image at a time`);
        setIsImageUploading(false);
      });
  };

  const setInitialValueEditor = (log: string) => {
    if (
      field.value &&
      tinyMce.current &&
      tinyMce.current.getContent() !== field.value
    ) {
      tinyMce.current.setContent(field.value, { no_events: true });
      console.log('Editor Reached!! => ', log);
    }
  };

  const onLoadContentHandler = () => {
    timerId = setTimeout(() => {
      setIsEditorLoaded(true);
      setInitialValueEditor('onLoadContentHandler');

      timerId2 = setTimeout(() => {
        setDisabledEditor(false);
      }, 1000);
    }, 1000);
  };

  const onBlurHandler = (_: any, editor: any) => {
    helpers.setValue(editor.getContent());
  };

  return (
    <>
      <Label label={label} required={required} />

      <RichTextContainer>
        <Editor
          id={id}
          disabled={!!isImageUploading || !isEditorLoaded || disabledEditor}
          init={config}
          apiKey="bahtirdvy7sfik5z35kzbe6gls7vmy0f5oeh0jcj1f5atmes"
          value={isEditorLoaded ? field.value : ''}
          outputFormat="text"
          textareaName={`editor-${name}`}
          onLoadContent={onLoadContentHandler}
          onBlur={onBlurHandler}
        />

        {!isEditorLoaded || disabledEditor ? (
          <LoadingRichText>Loading...</LoadingRichText>
        ) : (
          ''
        )}
      </RichTextContainer>

      <ErrorMessage meta={meta} />

      {isImageUploading ? (
        <UploadingOverlay>Please wait, image uploading...</UploadingOverlay>
      ) : (
        ''
      )}
    </>
  );
};

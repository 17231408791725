import { client } from './client';
import {
  IDocument,
  IListParams,
  ObjectId,
  PopulatedDocument,
  PopulatedUserDocument,
} from './types';

export interface IQuestionDocument extends IQuestion, IDocument {
  addedBy: ObjectId | PopulatedUserDocument;
}

export interface IQuestionListParams extends IListParams {
  subject?: ObjectId;
  topic?: ObjectId;
  addedBy?: ObjectId;
}

export interface IQuestionListResponse {
  questions: IQuestionDocument[];
  totalItems: number;
}

export interface IQuestion {
  subject: ObjectId | PopulatedDocument;
  difficulty: string;
  topic: ObjectId | PopulatedDocument;
  questionType: string;
  hasCommonData: boolean;
  description: string;
  questions: IQuestionItem[];
}

export interface IQuestionItem {
  text: string;
  options: IOption[];
  explanation: string;
}

export interface IOption {
  text: string;
  isAnswer: boolean;
}

const get = (id: string, params?: { populate: boolean }) => {
  return client.get<IQuestionDocument>(`/batch-questions/${id}`, { params });
};

const create = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/batch-questions`, question);
};

const createAll = (question: IQuestion) => {
  return client.post<IQuestionDocument>(`/batch-questions/batch`, question);
};

const update = (question: IQuestionDocument) => {
  return client.put<IQuestionDocument>(
    `/batch-questions/${question._id}`,
    question
  );
};

const remove = (id: string) => {
  return client.delete(`/batch-questions/${id}`);
};

const list = (params: IQuestionListParams) => {
  return client.get<IQuestionListResponse>(`/batch-questions/`, { params });
};

export const batchQuestion = { get, create, update, remove, list, createAll };

import Styled from 'styled-components';
import { ListProps } from './list.types';

export const List = Styled.div`
    width: 100%;
    /* max-width: 700px; */
    height: auto;
    background-color: #fff;
`;

export const ListItem = Styled.div`
    width: 100%;
    display: flex;
    padding: 5px 15px;
`;

export const ListItemImage = Styled.div<ListProps>`
    min-width: 80px;
    height: 55px;
    border: 1px solid silver;
    margin:10px;
    background-image: url(${props => (props.url ? props.url : '')});

/* background-image: url(${({ url }: any) => url}); */
background-repeat: no - repeat;
background-size: cover;
`;

export const ListItemText = Styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width:100%;
    margin:10px;
`;

export const TextPrimary = Styled.span`
    color: #434544;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.07;
    
`;

export const TextSecondary = Styled.span`
    color: #434544;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.07;
    margin-top: 5px;
`;

export const Divider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #f5f7fa;
`;

export const Menu = Styled.ul`
    position: absolute;
    display: none;
    width: auto;
    min-width: 10rem;
    right: 100%;
    top: 15px;
    border-radius: 6px;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6e6;
    padding: 5px 0px;
    z-index: 999;
    li{
        list-style:none;
        padding: .5rem 1rem;
        font-size: 14px;
        color: #434544;
				white-space: nowrap;
				a {
					transition: none
				}
        &:hover{
            background: #239F87;
            color: white;
						cursor: pointer;
						a {
							color: white;
							transition: none
						}
        }
    }
`;

export const ActionButton = Styled.div`
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
        cursor: pointer;
        background-color: rgba(0,0,0,0.03);
    }
`;

export const ListItemAction = Styled.div`
    float:right;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
    &:hover ${Menu}{
        display: block;
    }
`;

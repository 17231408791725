import React from 'react';
import { Pagination } from '../pagination/pagintaion';
import { Table } from '../table/table';
import { IPaginatedTableProps } from '../table/table.types';

const PaginatedTable = ({
  columns,
  data,
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  isDraggable,
  handleOnDragOver,
  handleOnDragEnd,
}: IPaginatedTableProps) => {
  return (
    // <div className="card p-relative mb-lg-32pt">
    //   <Table columns={columns} data={data} />
    //   <div className="card-footer p-8pt">
    //     <Pagination
    //       totalItems={totalItems}
    //       itemsPerPage={itemsPerPage}
    //       currentPage={currentPage}
    //       onPageChange={onPageChange}
    //     />
    //   </div>
    // </div>
    <>
      <Table
        columns={columns}
        data={data}
        isDraggable={isDraggable}
        handleOnDragOver={handleOnDragOver}
        handleOnDragEnd={handleOnDragEnd}
      />
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export { PaginatedTable };

import { client } from '../client';
import { IDocument, IListParams, ObjectId } from '../types';

export enum UserRole {
  USER = 'user',
  EDUCATOR = 'educator',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
  CONTRIBUTOR = 'contributor',
}

export interface IUser {
  name: string;
  email: string;
  role: UserRole;
  permissions?: any[];
  mobile?: string;
  id?: string;
  otp?: string;
  getToken?: string;
  hasPassword?: boolean;
}

export interface IMobileVerify {
  id: string;
  getToken: boolean;
  otp: string;
}

export interface IUserDocument extends IUser, IDocument {}

export interface IUserListParams extends IListParams {
  asset?: ObjectId;
  course?: ObjectId;
  subject?: ObjectId;
  addedBy?: ObjectId;
  role?: 'EDUCATOR' | 'USER';
}

export interface IUserListResponse {
  users: IUser[];
  totalItems: number;
}

export interface IStudentBulkUpload {
  course: string;
  group: string;
  file: string;
}
export interface IResetPassword {
  userId: string;
  token: string;
  password: string;
}

const get = (id: string) => {
  return client.get<IUserDocument>(`/users/${id}`);
};

const me = () => {
  return client.get<IUserDocument>(`/users/me`, {
    responseType: 'json',
  });
};

const create = (user: IUser) => {
  return client.post<IUserDocument>(`/users`, user);
};
const createOtp = (user: IUser) => {
  return client.post<IUserDocument>(`/users/otp`, user);
};
const sendOTP = (userId: string) => {
  return client.post(`/users/sendotp`, { id: userId });
};
const verifyMobile = (data: IMobileVerify) => {
  return client.post(`/users/verify-mobile`, data);
};

const setPassword = (password: string) => {
  return client.post(`/users/set-password`, { password });
};

const setUserData = (data: { name: string; email: string }) => {
  return client.post(`/users/set-user-data`, data);
};

const update = (user: IUserDocument) => {
  return client.put<IUserDocument>(`/users/${user._id}`, user);
};

const remove = (id: string) => {
  return client.delete(`/users/${id}`);
};

const list = (params: IUserListParams) => {
  return client.get<IUserListResponse>(`/users/`, { params });
};

const listEducators = (params: IUserListParams) => {
  return list({ ...params, role: 'EDUCATOR' });
};

const listStudents = (params: IUserListParams) => {
  return list({ ...params, role: 'USER' });
};

const bulkUploadStudents = (users: IStudentBulkUpload) => {
  return client.post<any>(`/users/upload`, users);
};

const changePassword = (id: string, params: any) => {
  return client.put(`/users/${id}/password`, params);
};

const sendResetPasswordMail = (body: { email: string }) => {
  return client.post('/users/reset-password-mail', body);
};

const resetPassword = (body: IResetPassword) => {
  return client.post('/users/reset-password', body);
};

const verifyEmailAddress = (userId: string, token: string) => {
  return client.get(`/users/verify-email/${userId}/${token}`);
};

const validatePasswordLink = (userId: string, token: string) => {
  return client.get(`/users/validate-password-link/${userId}/${token}`);
};

export const user = {
  get,
  create,
  createOtp,
  sendOTP,
  verifyMobile,
  setPassword,
  setUserData,
  update,
  remove,
  list,
  me,
  listEducators,
  listStudents,
  changePassword,
  bulkUploadStudents,
  sendResetPasswordMail,
  resetPassword,
  verifyEmailAddress,
  validatePasswordLink,
};

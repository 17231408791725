import React from 'react';
import Styled, { css } from 'styled-components';
import { ButtonProps } from './button.types';

const CustomButton = Styled.button<ButtonProps>`
  // background: transparent;
  // border: 0px;
  // font-size: 13px;
  // font-weight: 500;
  // color: #434544;

  ${props =>
    props.shape === 'popup' &&
    css`
      // background: #239f87;
      // color: white;
      // border: 1px solid #e1e3e7;

      // &:hover {
      //   background: #239f87;
      //   color: white;
      // }
    `}

  ${props =>
    props.shape === 'primary' &&
    css`
      // background: #ffe200;
      // color: black;
      // border: 1px solid #e1e3e7;

      // &:hover {
      //   background: #ffe200;
      // }
    `}

  ${props =>
    props.shape === 'secondary' &&
    css`
      // background: #f5f7fa;
      // color: black;
      // border: 1px solid #acb0b5;

      // &:hover {
      //   background: #f5f7fa;
      // }
    `}
`;

export const Button = ({
  id,
  shape,
  className,
  children,
  size,
  onClick,
  type,
}: ButtonProps) => {
  return (
    <CustomButton
      id={id}
      shape={shape}
      type={type || 'button'}
      className={`${className} text-uppercase btn-${size} primary-button`}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  );
};

import React from 'react';

interface IAdvertisement {
  rewardsPoint?: string;
  viewDetailsClick?: any;
}

const Advertisement = ({ rewardsPoint, viewDetailsClick }: IAdvertisement) => {
  return (
    <div className="advertisement-block">
      <div className="rewards-points">
        <div className="wrap">
          <p>Reward Points</p>
          <span>{rewardsPoint}</span>
          <button type="button" onClick={viewDetailsClick}>
            View Details
          </button>
        </div>
      </div>
      <div className="advertisement-banner">
        <p>Advertising Banner</p>
      </div>
      <div className="advertisement-banner">
        <p>Advertising Banner</p>
      </div>
    </div>
  );
};

export { Advertisement };

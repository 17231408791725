import React from 'react';
import { Link, Route } from 'react-router-dom';

interface IMenuItem {
  name: string;
  link: string;
  roles?: string[];
  items?: IMenuItem[];
}

const NavItem = ({
  menuItem,
  activeOnlyWhenExact,
}: {
  menuItem: IMenuItem;
  activeOnlyWhenExact: boolean;
}) => {
  return (
    <Route
      path={menuItem.link}
      exact={activeOnlyWhenExact}
      children={({ match }: any) => (
        <li className={`sidebar-menu-item ${match ? 'active' : ''}`}>
          <Link className="sidebar-menu-button" to={menuItem.link}>
            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">
              home
            </span>
            <span className="sidebar-menu-text">{menuItem.name}</span>
          </Link>
        </li>
      )}
    />
  );
};

const Sidebar = ({ menu }: { menu: IMenuItem[] }) => {
  return (
    <div
      className="mdk-drawer"
      id="default-drawer"
      data-align="start"
      data-position="left"
      data-domfactory-upgraded="mdk-drawer"
      data-persistent=""
      data-opened=""
    >
      <div className="mdk-drawer__content top-navbar">
        <div
          className="sidebar sidebar-dark-dodger-blue sidebar-left sidebar-p-t ps ps--active-y"
          data-perfect-scrollbar=""
        >
          {menu.map((menuHeading, index) => {
            return (
              <React.Fragment key={index}>
                <div className="sidebar-heading">{menuHeading.name}</div>
                <ul className="sidebar-menu">
                  {menuHeading.items &&
                    menuHeading.items.map(menuItem => {
                      return (
                        <NavItem
                          key={menuItem.link}
                          activeOnlyWhenExact={false}
                          menuItem={menuItem}
                        />
                      );
                    })}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { Sidebar };

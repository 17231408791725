import React from 'react';

export const Loader = () => {
  return (
    <div className="full-page-loader">
      {/* TODO Change Path to PROD */}
      <img
        src={
          'https://dev.educrack.com/static-assets/asset/image/Loading_effect.gif'
        }
        alt=""
      />
      {/* <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
      <p>Loading...</p>
    </div>
  );
};
